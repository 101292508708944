.fighter-card {
	@extend .flex-centered-column;
	
	display: flex!important;

	.fighter-image {
		@extend .standard-background-image;

		$fighter-image-size: 140px;

		width: $fighter-image-size;
		height: $fighter-image-size;
		border-radius: $fighter-image-size * 0.5;
		border: 1px solid $border-color;
		margin-bottom: -10px;
	}

	.fighter-info {
		@extend .flex-centered-column;

		span {
			display: inline-block;
		}
	}
}
