.loading-overlay {
	@extend .fixed-overlay;	

	& > .flex-centered-column {
		width: 100%;

		img {
			width: 40%;
			min-width: 100px;
			max-width: 200px;
		}

		p {
			color: $gray;
		}
	}
}