.slick-arrow::before,
.slick-arrow::after {
	color: $primary !important;
	font-size: 25px !important;
}

.fighter-slide {
	@extend .flex-centered-column;

	position: relative;
	margin-left: 25px;
	margin-right: 25px;

	.fighter-image {
		width: 100%;
		margin-bottom: 60px;
		background-size: contain;
		background-position: bottom;
		background-repeat: no-repeat;
		aspect-ratio: 1 / 1;
		max-height: 400px;
	}

	.fighter-banner-container {
		@extend .flex-centered-column;

		position: absolute;
		bottom: 0;

		span {
			@extend .skew;

			display: inline-block;
			text-align: center;
			color: $light-gray;
		}

		.fighter-name {
			font-family: "Audiowide", sans-serif;
			font-size: 2.7rem;
			background-color: $dark-gray;

			@include mobile {
				font-size: 2rem;
			}
		}
	}
}

.rdg-header-row > div,
.rdg-row > div {
	@extend .p-h-xxs;
}

.fighter-subcontent {
	@extend .p-s;

	position: relative;
	background-color: $gray;
	height: 475px;
	color: $white;
	overflow: hidden;

	.fighter-stats {
		position: absolute;
		width: 100%;
		z-index: 1000;

		.fighter-stat-container {
			@extend .m-b-xs;

			.stat-value {
				display: inline-block;
				font-size: 2rem;
				font-weight: 600;
				width: 80px;
				border-right: 2px solid $primary;
				text-align: center;
			}

			.stat-name {
				@extend .m-l-s;

				display: inline-block;
				text-transform: uppercase;
				font-size: 0.65rem;
				font-weight: 600;
				width: 80px;
				text-align: center;
			}
		}

		.fighter-donut-stat {
			position: relative;
			width: 160px;

			.donut-stat-value {
				position: absolute;
				top: 41px;
				left: 57px;
				font-size: 1.3rem;
				font-weight: 600;
				width: 50px;
				text-align: center;
			}

			.donut-stat-name {
				display: block;
				text-transform: uppercase;
				font-size: 0.65rem;
				font-weight: 600;
				text-align: center;
			}

			@include mobile {
				margin-left: -20px;
			}
		}
	}

	.fighter-subcontent-image {
		@extend .standard-background-image;

		position: absolute;
		background-color: $gray;
		bottom: 0;
		right: 0;
		height: 100%;
		width: 100%;
		z-index: 999;
		background-size: contain;
		background-position: right;

		@include mobile {
			background-size: cover;
		}
	}
}
