.avatar-container {
	width: 70%;
	max-width: 200px;
	aspect-ratio: 1;
	border-radius: 50%;
	overflow: hidden;
	background-color: $light-gray;

	svg {
		transform: skew(-16deg) translateX(-20%) translateY(-20%);
	}
}

.season-details {
	.banner-track {
		display: inline-block;
	}

	.profile-season-banner {
		@include mobile {
			font-size: 1.6rem;
		}
	}
}
