.rank {
	display: block;
	width: 34px;
	height: 34px;
	line-height: 34px;
	text-align: center;
	border-radius: 50%;
	background-color: $light-gray;
	font-size: 0.8rem;

	&.gold,
	&.silver,
	&.bronze {
		color: $light-gray;
		font-weight: 800;
	}

	&.gold {
		background: linear-gradient(#F2DE7A, #AE7C2B);
	}

	&.silver {
		background: linear-gradient(#F4F4F4, #545353);
	}

	&.bronze {
		background: linear-gradient(#F2BA7A, #AE492B);
	}
}