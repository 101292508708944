.btn {
	@extend .interactive;
	@extend .p-h-s;
	@extend .flex-center;
	// This was added additionally.
	@extend .limited-text;

	display: inline-block; // This was inline-flex but was changed because of text-ellipsis.
	height: $button-height;
	line-height: calc($button-height - 4px);
	background-color: $black;
	color: $white;
	text-align: center;
	border-radius: calc($button-height / 2);
	overflow: hidden;
	cursor: pointer;
	outline: none;
	font-weight: 600;
	font-size: 0.85rem;
	border: 2px solid $black;
	text-decoration: none;
	user-select: none;
	text-overflow: ellipsis;
	overflow: hidden;
	white-space: nowrap;

	span {
		color: inherit;
	}

	&.small {
		height: $button-height-small;
		line-height: calc($button-height-small - 4px);
		border-radius: calc($button-height-small / 2);
		font-weight: 600;
		font-size: 0.85rem;
		border: 2px solid $black;
	}

	&.primary {
		background-color: $primary;
		border-color: $primary;

		&.negative {
			color: $primary !important;
		}
	}

	&.orange {
		background-color: $orange;
		border-color: $orange;

		&.negative {
			color: $orange;
		}
	}

	&.blue {
		background-color: $blue;
		border-color: $blue;

		&.negative {
			color: $blue;
		}
	}

	&.red {
		background-color: $red;
		border-color: $red;

		&.negative {
			color: $red;
		}
	}

	&.green {
		background-color: $green;
		border-color: $green;

		&.negative {
			color: $green;
		}
	}

	&.gray {
		background-color: $gray;
		border-color: $gray;

		&.negative {
			color: $gray;
		}
	}

	&.light-gray {
		background-color: $light-gray;
		border-color: $light-gray;

		&.negative {
			color: $light-gray;
		}
	}

	&.cream-white {
		background-color: $light-gray;
		border-color: $light-gray;

		&.negative {
			color: $light-gray;
		}
	}

	&.white {
		background-color: $white;
		border-color: $white;
		color: $text-color;

		&.negative {
			color: $white;
		}
	}

	&.negative {
		color: $black;
		background: transparent;
	}

	&:disabled,
	&[disabled] {
		opacity: 0.3;
		transition: none;
		user-select: none;
		cursor: initial;
	}

	&.disabled {
		opacity: 0.3;
		transition: none;
		user-select: none;
		cursor: initial;
		pointer-events: none; // This is added in order to disable events through CSS.
	}
}

.full-width-button {
	.btn {
		width: 100%;
	}
}

.badge-button {
	.btn {
		min-width: 0;
		width: $button-height;
	}
}

// Multi-button
.multi-btn {
	@extend .flex-row;

	display: inline-flex;
	overflow: hidden;
	border-radius: calc($button-height / 2);

	.btn {
		border-radius: 0;
		margin: 0;
	}
}
