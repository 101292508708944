.data-table {
	background-color: $white;
	width: 100%;
	overflow: hidden;
	color: $gray;

	.data-table-header {
		@extend .p-h-xs;

		border-bottom: 1px solid $medium-gray;
		overflow-x: auto;
		overflow-y: hidden;
		height: 61px; // TODO: Remove hotfix.

		.column {
			@extend .p-v-s;
			@extend .p-h-xs;

			display: inline-block;
			min-width: min-content;

			> * {
				font-weight: 600;
			}
		}
	}

	.data-table-body {
		.row {
			@extend .p-h-xs;
			@extend .background-transition;

			cursor: pointer;
			overflow-x: auto;

			&:nth-child(even) {
				background-color: $cream-white;
			}

			&:hover {
				background-color: $light-gray;
			}

			.row-content {
				display: flex;
				align-items: center;

				.column {
					@extend .p-xs;

					display: inline-block;
					min-width: min-content;
				}
			}

			.row-subcontent {
				@extend .height-transition;

				height: 0;
				overflow: hidden;

				&.active {
					height: 475px;
				}

				img {
					width: 100%;
				}
			}
		}
	}

	.empty-table-disclaimer {
		color: $medium-gray;
	}
}
