.fights-container {
	@extend .flex-column;

	.fight-card {
		@extend .p-m;
		@extend .m-b-m;

		background-color: $white;

		&.past {
			-webkit-filter: grayscale(1);
		}

		.fighter-fight-image {
			@extend .standard-background-image;

			display: inline-block;
			width: 360px;
			height: 360px;

			&.left {
				margin-right: -60px;
				border-top-left-radius: 150px;
				border-bottom-left-radius: 150px;
			}

			&.right {
				border-top-right-radius: 150px;
				border-bottom-right-radius: 150px;
			}

			@include tablet {
				height: 320px;
				width: 320px;

				&.left {
					margin-right: -30px;
				}
			}

			@include mobile {
				height: 200px;
				width: 200px;

				&.left {
					margin-right: -30px;
					border-top-left-radius: 100px;
					border-bottom-left-radius: 100px;
				}

				&.right {
					border-top-right-radius: 100px;
					border-bottom-right-radius: 100px;
				}
			}
		}

		.fight-details {
			margin-top: -40px;

			.banner-track.large {
				@include mobile {
					font-size: 1.8rem;
				}
			}

			.banner-track.medium {
				@include mobile {
					font-size: 1rem;
				}
			}
		}
	}
}
