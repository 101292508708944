.auth-page {
	height: 100vh;

	.auth-form {
		width: 80%;
		max-width: 350px;

		.disclaimer {
			@extend .m-v-xs;
			@extend .p-h-xxs;

			font-size: 0.85rem;
		}

		z-index: 1000;
	}

	.auth-art-container {
		position: fixed;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;

		.auth-background-image {
			position: fixed;
			height: 100%;
			right: -300px;
			opacity: 0.15;
			z-index: 999;
		}

		.auth-logo {
			position: fixed;
			width: 100%;
			max-width: 90px;
			top: $space-l;
			left: $space-m;
		}
	}
}
