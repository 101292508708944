// Global
* {
	padding: 0;
	margin: 0;
	box-sizing: border-box;

	// Firefox scrollbar
	scrollbar-color: transparentize($text-color, 0.4) $white !important;
	scrollbar-width: thin !important;
}

:root {
	// For tables
	--rs-table-sort: #ff5a5f;
}

html {
	font-size: $font-size-desktop;
}

body {
	background-color: $background-gray;
	font-family: $font-family !important;
	min-width: $body-min-width;
	-webkit-tap-highlight-color: transparent;
	min-width: 360px;
}

// Chrome and Edge scrollbar
::-webkit-scrollbar {
	width: 8px;
}

::-webkit-scrollbar-track {
	background: transparent;
}

::-webkit-scrollbar-thumb {
	background-color: transparentize($text-color, 0.4);
	border-radius: 4px;
}

[placeholder] {
	text-overflow: ellipsis;
}

// react-ui-cards
.src-Card-styles-module__card--1s_T3 {
	margin: 0;
}

// react-pricing-table
.react-pricing-table {
	.basic-border {
		border-color: $border-color;
		border-radius: $border-radius-m;
		overflow: hidden;
	}

	.basic-header {
		@extend .m-b-s;

		font-size: 1rem;
		font-weight: 600;
	}

	.price .highlighted-header {
		@extend .m-b-s;

		background-color: $primary;
		color: $white;
		font-size: 1rem;
	}

	.button-submit {
		@extend .btn;
		@extend .m-b-s;

		border: none !important;
	}
}

// Google Translate SDK
#google-translate-element {
	background: transparent;
	list-style-type: none;
}

.goog-te-banner-frame {
	display: none;
}

.goog-te-gadget {
	display: inline-block;
	width: 205px;
	overflow: hidden;
	width: 100%;
}

.skiptranslate {
	&:not(.goog-te-gadget) {
		display: none;
	}

	.goog-te-combo {
		@extend .p-h-xs;

		margin-top: 0 !important;

		display: block;
		border: none;
		outline: none;
		height: 40px;
		line-height: 40px;
		width: 255px;
		background-color: $light-gray;
		cursor: pointer;
	}
}

.goog-logo-link {
	display: inline-block !important;
	font-size: 0.6rem !important;
	border-bottom: none !important;
	padding-right: 20px !important;

	img {
		display: inline-block !important;
		margin-right: 5px;
	}

	pointer-events: none;
}

// react-toast
.toast {
	border-radius: 0 !important;

	.icon,
	.close {
		border-radius: 50% !important;
	}

	.content {
		p {
			font-size: 0.9rem;
			font-weight: 400;
		}
	}
}

@include tablet {
	.goog-te-gadget {
		width: 100%;
	}

	.skiptranslate {
		margin-bottom: $space-xs;

		.goog-te-combo {
			width: 100%;
			max-width: 100%;
			height: 43px;
			line-height: 43px;
		}
	}
}

@include mobile {
	.goog-te-gadget {
		width: 100%;
	}

	.skiptranslate {
		margin-bottom: $space-xs;

		.goog-te-combo {
			width: 100%;
			max-width: 100%;
		}

		margin-top: $space-xs;
	}
}

// Slick
.slick-dots li {
	width: 14px !important;
}
