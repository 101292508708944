.app-layout-header {
	position: fixed;
	display: flex;
	align-items: center;
	left: 0;
	right: 0;
	top: 0;
	height: $header-height;
	min-width: 360px;
	background-color: $white;
	border-bottom: 1px solid $light-gray;
	// overflow: hidden; // TODO: Fix strange 1px space on bottom of header;
	z-index: 1001;

	& > * {
		vertical-align: top;
	}

	.burger-button-wrapper {
		@extend .flex-center;
		@extend .p-l-m;
		@extend .skew;

		display: inline-flex;
		background-color: $dark-gray;
		height: $header-height;
		width: $header-height + $space-m + $space-xs;
		margin-left: -$space-m;
	}

	.header-logo-container {
		@extend .flex-center;

		position: relative;
		display: inline-flex;

		.header-logo-skew {
			@extend .skew;

			background-color: $primary;
			height: $header-height;
			width: $header-height + $space-xs;
		}

		& > a {
			@extend .flex-center;

			position: absolute;
			height: $header-height;

			img {
				width: calc($header-height / 1.5);
			}
		}
	}

	.header-player-stats {
		@extend .p-h-s;
		@extend .flex-centered-column;
		@extend .skew;

		display: inline-flex;
		margin-left: auto;
		border-left: 2px solid $light-gray;
		height: $header-height;
		text-decoration: none;

		& > span {
			display: block;
			width: 100%;
			font-family: "Audiowide", sans-serif;
			font-weight: 600;
			text-align: right;
			font-size: 0.75rem;
			color: $tertiary;

			.stat-icon {
				font-family: "Poppins", sans-serif;
				font-size: 0.75rem;
				color: $primary;
			}
		}
	}
}

.side-menu {
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 300px;
	position: fixed;
	top: $header-height;
	bottom: 0;
	background-color: $light-gray;
}

.app-layout-menu {
	@extend .side-menu;
	@extend .p-s;

	left: 0;
	border-right: 1px solid $gray;
	z-index: 1002;

	.app-menu-logo {
		width: 70%;
	}
}

.my-lineup-menu {
	@extend .side-menu;

	right: 0;
	border-left: 1px solid $gray;
	z-index: 1001;
}

.my-lineup-button {
	@extend .interactive;
	@extend .flex-center;
	@extend .p-h-xs;
	@extend .width-transition;

	$lineup-button-height: $button-height * 1.1;

	position: fixed;
	display: flex;
	align-items: center;
	bottom: 30px;
	right: 30px;
	height: $lineup-button-height;
	width: $lineup-button-height;
	background-color: $primary;
	border-radius: $lineup-button-height * 0.5;
	border: none;
	outline: none;
	z-index: 9999;

	span {
		@extend .m-h-xxs;

		position: absolute;
		display: inline-block;
		left: 10px;
		height: 15px;
		opacity: 0;
		color: $white;
		font-weight: 600;
		font-size: 0.85rem;
		overflow: hidden;
		text-overflow: ellipsis;

		transition: opacity 0.3s ease-in-out 0.1s;
	}

	img {
		margin-left: auto;
		width: $button-height * 0.75;
	}

	&:hover {
		width: 129px;

		span {
			opacity: 1;
		}
	}
}

.app-layout-main {
	@extend .p-b-l;

	position: relative;
	margin-top: $header-height;
	overflow: hidden;
	z-index: 999;
}

.BurgerInner,
.BurgerInner:before,
.BurgerInner:after {
	background-color: $light-gray !important;
}

/* Position and sizing of burger button */
.bm-burger-button,
.bm-cross-button {
	display: none;
}

/*
  Sidebar wrapper styles
  Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
  */
.bm-menu-wrap {
	position: fixed;
	height: 100%;
	top: $header-height;
	background: $gray;
}

/* General sidebar styles */
.bm-menu {
	width: 100% !important;
	height: calc(100vh - $header-height) !important;
	padding: 2.5em 1.5em 0;
	font-size: 1.15em;
	white-space: initial !important;
	overflow-y: auto !important;

	& > nav {
		height: auto !important;

		a {
			@extend .m-b-xs;

			display: block;
			color: $white !important;
			text-decoration: none;
			font-size: 1rem !important;
		}
	}

	* {
		outline: none !important;
	}
}

/* Styling of overlay */
.bm-overlay {
	background: rgba(0, 0, 0, 0.3);
	top: $header-height;
}

// Bottom content
.bottom-content {
	position: absolute;
	bottom: 100px;
	left: 10%;
	width: 80%;
}

// Abstract background art
@keyframes abstract1 {
	0% {
		transform: translateX(0) translateY(0);
	}
	50% {
		transform: translateX(-100px) translateY(100px);
	}
	100% {
		transform: translateX(0) translateY(0);
	}
}

@keyframes abstract2 {
	0% {
		transform: translateX(0) translateY(0);
	}
	50% {
		transform: translateX(100px) translateY(-100px);
	}
	100% {
		transform: translateX(0) translateY(0);
	}
}

.abstract-art-container {
	position: fixed;
	z-index: 0;

	img {
		position: fixed;
		opacity: 0.2;
		top: 0;

		&.left {
			animation: abstract2 120s linear infinite;

			left: -200px;
		}

		&.right {
			animation: abstract1 120s linear infinite;

			right: -200px;
		}

		@include tablet {
			&.left {
				left: -300px;
			}

			&.right {
				right: -300px;
			}
		}

		@include mobile {
			opacity: 0.1;

			&.left {
				left: -350px;
			}

			&.right {
				right: -350px;
			}
		}
	}
}
